import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { DocumentsListComponent } from '../../document';
import { Button, FieldFile } from '../../ui';
import { useModal } from '../../../contexts/side-modal.context';
import { SelectFileList } from '../../candidate';
import { ComplianceFile, HostedFile } from '../../../backend/careo-api';
import { uploadCandidateFile } from '../../../utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

type ComplianceUploadProps<T> = {
  fileKey: T;
  label: string;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  filesList: { key: T; data: ComplianceFile[] }[];
  setFilesList: Dispatch<SetStateAction<{ key: T; data: ComplianceFile[] }[]>>;
  documents: HostedFile[];
  description?: ReactNode;
  onUpdateCompliance: (
    key: T,
    value: {
      file: string;
      archiveDate?: string;
    }[],
    successMessage?: string,
  ) => void;
  isMulti?: boolean;
};

const ComplianceUpload = <T extends string>({
  fileKey,
  filesList,
  setFilesList,
  label,
  getDocuments,
  reFetchCompliance,
  documents,
  description,
  onUpdateCompliance,
  isMulti,
}: ComplianceUploadProps<T>) => {
  const { id } = useParams();
  const { openModal, closeModal } = useModal();

  const getFiles = (key: T): HostedFile[] => {
    const data = (filesList.find((el) => el.key === key) ?? { data: [] })?.data
      .filter((el) => !el.archiveDate)
      .map((el) => el.file)
      .sort((a, b) => {
        return (
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
      });

    if (isMulti) {
      return data;
    }

    if (data?.length) {
      return [data[0]];
    }

    return [];
  };

  const onClickEdit = (fileKey: T) => {
    openModal({
      title: 'Select File',
      component: (
        <SelectFileList
          fileKey={fileKey}
          onCancel={() => closeModal()}
          documents={documents}
          addNewFile={addNewFile}
          data-testid="select-file-list"
        />
      ),
    });
  };

  const addNewFile = (key: T, file: HostedFile) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      if (!isMulti) {
        prev[itemIndex].data = prev[itemIndex].data.map((el) => ({
          ...el,
          archiveDate: new Date().toJSON(),
        }));
      }
      prev[itemIndex].data?.push({ file, addedDate: new Date().toJSON() });
      onUpdateCompliance(
        key,
        prev[itemIndex].data.map((el) => ({ ...el, file: el.file._id })),
      );
      return [...prev];
    });
  };

  const onClickDelete = async (fileId: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === fileKey);
      prev[itemIndex].data = prev[itemIndex].data.map((el) => {
        if (el.file._id === fileId) {
          return { ...el, archiveDate: new Date().toJSON() };
        }
        return el;
      });

      onUpdateCompliance(
        fileKey,
        prev[itemIndex].data.map((el) => ({ ...el, file: el.file._id })),
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: T,
  ) => {
    const selectedFile = event.target?.files?.[0];
    if (selectedFile) {
      // 5 MB file size limit
      if (selectedFile.size > +process.env.MAXIMUM_FILE_SIZE! * 1024 * 1024) {
        alert(`File size is bigger than ${process.env.MAXIMUM_FILE_SIZE} MB!`);
        return;
      }

      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  return (
    <>
      <div
        className="row upload-buttons-container"
        data-testid={`upload-buttons-${fileKey}`}
      >
        <div className="col-md-6" data-testid={`col-upload-${fileKey}`}>
          <FieldFile
            label={label}
            onChange={(e) => handleFileChange(e, fileKey)}
            data-testid={`field-file-${fileKey}`}
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => onClickEdit(fileKey)}
          data-testid={`select-${fileKey}-btn`}
        >
          <Button type="primary" data-testid={`select-${fileKey}-btn-label`}>
            Select from files
          </Button>
        </div>
      </div>
      <div className="description">
        Max file size : 5MB. File format : pdf, docx, png, and jpeg <br />
        {description}
      </div>
      <DocumentsListComponent
        documents={getFiles(fileKey)}
        onClickDelete={onClickDelete}
        getDocuments={getDocuments}
        onDocumentRename={() => reFetchCompliance?.()}
        data-testid={`documents-list-${fileKey}`}
      />
    </>
  );
};

export default ComplianceUpload;
